import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"
import React from "react"
import ContattiComponent from "../components/ContattiComponent"
import Layout from "../components/Layout"
import SEO from "../components/seo"

class Contatti extends React.Component {
  constructor(props) {
    super(props)
    this.state = { showMobileMenu: false }
  }

  toggleMenu() {
    this.setState(prevState => {
      return { showMobileMenu: !prevState.showMobileMenu }
    })
  }

  render() {
    const { data } = this.props
    const { showMobileMenu } = this.state

    return (
      <Layout
        showMobileMenu={showMobileMenu}
        toggleMenu={this.toggleMenu.bind(this)}
      >
        <SEO />
        <FormattedHTMLMessage id="Contatti-moredescription:label" />
        <ContattiComponent data={data} />
        <FormattedHTMLMessage id="Contatti-moreInfo:label" />
      </Layout>
    )
  }
}

export default injectIntl(Contatti)
