import facebook from "../images/facebook.png"
import linkedin from "../images/linkedin.png"

export default [
  {
    id: "linkedin",
    url: "https://www.linkedin.com/company/lunasol-impresa-te/",
    image: linkedin,
  },
  {
    id: "facebook",
    url:
      "https://www.facebook.com/Lunasol-impresa-di-pulizie-di-Corropoli-TE-122225269167160",
    image: facebook,
  },
]
