import { navigateTo } from "gatsby-link"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import "./Form.css"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    console.log(this.state)

    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action")))
      .catch(error => alert(error))
  }

  render() {
    const { formName, intl } = this.props

    return (
      <form
        className="Form"
        name={formName}
        method="post"
        action="/sent-message-success-page/"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
      >
        <p hidden>
          <label>
            Don’t fill this out:{" "}
            <input name="bot-field" onChange={this.handleChange} />
          </label>
        </p>
        <div className="field half first">
          <label htmlFor="name">
            {intl.formatMessage({ id: "name:label" })}
          </label>
          <br />
          <input
            type="text"
            name="name"
            id="name"
            placeholder={intl.formatMessage({ id: `name-placeholder:label` })}
            onChange={this.handleChange}
          />
        </div>
        <div className="field half">
          <label htmlFor="email">
            {intl.formatMessage({ id: "email:label" })}
          </label>
          <br />
          <input
            type="text"
            name="email"
            id="email"
            placeholder={intl.formatMessage({ id: `email-placeholder:label` })}
            onChange={this.handleChange}
          />
        </div>
        <div className="field">
          <label htmlFor="message">
            {intl.formatMessage({ id: "message:label" })}
          </label>
          <br />
          <textarea
            name="message"
            id="message"
            placeholder={intl.formatMessage({
              id: `message-placeholder:label`,
            })}
            rows="6"
            onChange={this.handleChange}
          />
        </div>
        <ul className="actions">
          <li>
            <input
              type="submit"
              value={`${intl.formatMessage({ id: "sendMessage:label" })}`}
              className="special"
            />
          </li>
          <li>
            <input
              type="reset"
              className="clearFields"
              value={`${intl.formatMessage({ id: "clearFields:label" })}`}
            />
          </li>
        </ul>
      </form>
    )
  }
}

export default injectIntl(Form)
