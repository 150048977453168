import React from "react"
import socials from "../sources/socials"

const SocialContainer = () => (
  <div className={"SocialContainer"}>
    {socials.map((social, index) => {
      return (
        <a
          key={index}
          href={social.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="singleSocial" key={index}>
            <img src={social.image} alt="" />
          </div>
        </a>
      )
    })}
  </div>
)

export default SocialContainer
