import { FormattedHTMLMessage, injectIntl } from "gatsby-plugin-intl"
import React from "react"
import Form from "../components/Form"

const ContattiComponent = ({ intl }) => (
  <div>
    <FormattedHTMLMessage id={"Contatti-description:label"} />
    <Form formName="Contatti" />
  </div>
)

export default injectIntl(ContattiComponent)
