/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Header from "./Header"
import "./Layout.css"
import MobileMenu from "./MobileMenu"

const Layout = ({ children, showMobileMenu, toggleMenu, hideHamburger, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={`main-container ${className}`}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        toggleMenu={toggleMenu}
        hideHamburger={hideHamburger}
      />
      {showMobileMenu === true ? (
        <MobileMenu showMobileMenu={showMobileMenu} toggleMenu={toggleMenu} />
      ) : null}
      <main>{children}</main>
      <footer>© Yang P {new Date().getFullYear()}</footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
