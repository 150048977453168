import { Link } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import React from "react"
import menuItems from "../sources/menuItems"
import "./Nav.css"

const filteredMenuItems = menuItems.filter(i => {
  return i.notInMenu !== true
})

const Nav = ({ showMobileMenu, toggleMenu, intl }) => (
  <nav>
    <ul>
      <li>
        <Link to={`/#`}>
          <span>{intl.formatMessage({ id: `Home:label` })}</span>
        </Link>
      </li>
      {filteredMenuItems.map((menuItem, index) => {
        return (
          <li key={index}>
            <Link to={`/${menuItem.id}`}>
              <span>{intl.formatMessage({ id: `${menuItem.id}:label` })}</span>
            </Link>
          </li>
        )
      })}
    </ul>
  </nav>
)

export default injectIntl(Nav)
