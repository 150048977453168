import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "../images/Lunasol-logo.png"
import hamburger from "../images/hamburger.png"
import "./Header.css"
import Nav from "./Nav"
import SocialContainer from "./SocialContainer"
const Header = ({ toggleMenu, hideHamburger }) => (
  <header>
    {hideHamburger === true ? null : (
      <div className="hamburger-menu-icon" onClick={() => toggleMenu()}>
        <img src={hamburger} alt="" />
      </div>
    )}

    <Link className="logo-site-link" to="/" href="#">
      <img className="logo-site" src={logo} alt="logo" />
    </Link>
    <Nav />
    <SocialContainer />
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
