import React from "react"
import "./MobileMenu.css"
import Nav from "./Nav"
const MobileMenu = ({ showMobileMenu, toggleMenu }) => (
  <div className={"MobileMenu"}>
    <Nav showMobileMenu={showMobileMenu} toggleMenu={toggleMenu} />
    <div className="overlay" onClick={() => toggleMenu()} />
  </div>
)

export default MobileMenu
